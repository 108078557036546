import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  imgSection: {
    "& img": {
      width: "90px",

      "@media(max-width:991px)": {
        width: "80px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 auto",
      },
    },
  },
}));
const Logo = (props) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box className={classes.imgSection}>
      <img
        onClick={() => history.push("/")}
        src="/images/inbt.png"
        alt="Logo"
        style={{ cursor: "pointer" }}
        {...props}
      />
    </Box>
  );
};

export default Logo;
