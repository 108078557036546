// const url = "http://172.16.1.145:7010";
// const url = "https://backend.togatoken.com";
//const url = "https://java-tokenico.mobiloitte.com";
const url ="https://nodepune-icoinbt.mobiloitte.com"
  
//const url="http://localhost:3013";

const user = `${url}/user`;
const auth = `${url}/user`;
const account = `${url}/user`;
const wallet = `${url}/wallet`;
const static1 = `${url}/static`;
const faq= `${url}/user`
const admin = `${url}/wallet/admin`;
const notification = `${url}/notification`;
const whitepaper= `${url}/user`;
const community= `${url}/user`;
const contact= `${url}/user`;

const ApiConfig = {
  userLogin: `${auth}/user-login`,
  getCoinList: `${url}/wallet/coin/get-coin-list`,
  // userSignUp: `${user}/user-signup`,
  signup:`${user}/user-signup`,
  verifyOTP: `${user}/user-Sign-In-Email-OTP`,
  resendOTP: `${user}/resend-verify-email`,
  forgotPassword: `${user}/forgot-password-send-otp-email`, ///verify-sms-code-mobile-app
  verifyOTPSMS: `${user}/verify-sms-code-mobile-app`, //verify-sms-code-mobile-app
  resetPassword: `${user}/reset-password-mobile-app`,
  getKYCDetails: `${account}/get-kyc-details`,
  adminstatusforkyc: `${account}/get-check-kyc-status`,
  // editUserProfile: `${user}/profile-update`,
  getCountryList: `${user}/get-country-list`,
  getTransactionHistory: `${url}/wallet/wallet/get-all-transaction-history`,

  addSubscribe: `${user}/add-subscribe`,

  // account

  myAccount: `${account}/my-account`,
  userBankAccount: `${account}/add-user-bank-account`,
  userAccountDetails: `${account}/get-user-account-detail`,
  userAccountList: `${account}/get-user-account-list`,
  userAccountDelete: `${account}/delete-user-bank-account`,
  saveKycDetails: `${account}/savekycdetails`, 
  uploadFile: `${account}/upload-file`,
  getKycList: `${account}/get-all-kyc-details`,
  editUserProfile: `${account}/profile-update`,
  CountryList: `${account}/get-country-list`,
  searhList: `${account}/search-and-filter-bank-account-list`,
  newsletter: `${account}/get-all-news-letter-for-website`,
  whitePaperList: `${whitepaper}/getwhitepaperfulllist`,

  // wallet

  myWallet: `${wallet}/get-all-user-balance-and-coinlist`,
  // static
  contactUS: `${contact}/submitSupportTicket`,
  postnewsLetter: `${static1}/post-news-letter-to-user`,
  // term: `${static1}/get-all-static-content-data`,
  faq: `${faq}/getAllFAQ`,
  term: `${static1}/get-static-page-data-by-page-key`,
  Aboutus: `${static1}/get-all-static-content-data`,
  policy: `${static1}/get-all-static-content-details-for-web`,
  GetAllCommunityForWebsite: `${community}/get-all-community-for-website`,
  // policy: `${static1}/get-static-page-data-by-page-key?pageKey=Privacy_Policy`,
  myWallet: `${wallet}/wallet/get-all-user-balance-and-coinlist`,
  getCurrentprice: `${wallet}/wallet/get-price?`,
  toaddress: `${wallet}/wallet/get-all-coin-with-wallet-address`,

  myAddress: `${wallet}/wallet/get-address-new`,
  withdraw: `${wallet}/wallet/withdraw`,
  approveWithdraw: `${wallet}/wallet/approve-withdraw`,

  cancelWithdraw: `${wallet}/wallet/cancel-withdraw`,
  deposits: `${wallet}/wallet/get-deposits`,
  buyToken: `${wallet}/basic-exchange/buy-token`,
  buyTokenBtc: `${wallet}/wallet/buy-token`,
  minimumfee: `${wallet}/wallet/coin/get-coin-list`,

  // admin /wallet/coin/get-coin-list
  transactionHistoy: `${wallet}/get-all-transaction-history`,

  // notification
  getNotification: `${notification}/get-notification-data`,
  readNotification: `${notification}/read-notification`,
  clearNotification: `${notification}/clear-notification`,

  // account
};

export default ApiConfig;







// const url = "http://172.16.1.145:7010";
// const url = "https://backend.togatoken.com";
// const url = "https://java-tokenico.mobiloitte.com";
// const user = `${url}/account`;
// const auth = `${url}/auth`;
// const account = `${url}/account`;
// const wallet = `${url}/wallet`;
// const static1 = `${url}/static`;
// const admin = `${url}/wallet/admin`;
// const notification = `${url}/notification`;

// const ApiConfig = {
//   userLogin: `${auth}`,
//   getCoinList: `${url}/wallet/coin/get-coin-list`,
//   userSignUp: `${user}/signup`,
//   verifyOTP: `${user}/verify-user`,
//   resendOTP: `${user}/resend-verify-email`,
//   forgotPassword: `${user}/forget-password/mobiloitApp`, ///verify-sms-code-mobile-app
//   verifyOTPSMS: `${user}/verify-sms-code-mobile-app`, //verify-sms-code-mobile-app
//   resetPassword: `${user}/reset-password-mobile-app`,
//   getKYCDetails: `${account}/get-kyc-details`,
//   adminstatusforkyc: `${account}/get-check-kyc-status`,
//   // editUserProfile: `${user}/profile-update`,
//   getCountryList: `${user}/get-country-list`,
//   getTransactionHistory: `${url}/wallet/wallet/get-all-transaction-history`,

//   addSubscribe: `${user}/add-subscribe`,

//   // account

//   myAccount: `${account}/my-account`,
//   userBankAccount: `${account}/add-user-bank-account`,
//   userAccountDetails: `${account}/get-user-account-detail`,
//   userAccountList: `${account}/get-user-account-list`,
//   userAccountDelete: `${account}/delete-user-bank-account`,
//   saveKycDetails: `${account}/save-kyc-details`,
//   uploadFile: `${account}/upload-file`,
//   getKycList: `${account}/get-all-kyc-details`,
//   editUserProfile: `${account}/profile-update`,
//   CountryList: `${account}/get-country-list`,
//   searhList: `${account}/search-and-filter-bank-account-list`,
//   newsletter: `${account}/get-all-news-letter-for-website`,
//   whitePaperList: `${account}/get-whitepaper-full-list`,

//   // wallet

//   myWallet: `${wallet}/get-all-user-balance-and-coinlist`,
//   // static
//   contactUS: `${static1}/submit-support-ticket`,
//   postnewsLetter: `${static1}/post-news-letter-to-user`,
//   // term: `${static1}/get-all-static-content-data`,
//   faq: `${static1}/search-filter-faq-list`,
//   term: `${static1}/get-static-page-data-by-page-key`,
//   Aboutus: `${static1}/get-all-static-content-data`,
//   policy: `${static1}/get-all-static-content-details-for-web`,
//   GetAllCommunityForWebsite: `${account}/get-all-community-for-website`,
//   // policy: `${static1}/get-static-page-data-by-page-key?pageKey=Privacy_Policy`,
//   myWallet: `${wallet}/wallet/get-all-user-balance-and-coinlist`,
//   getCurrentprice: `${wallet}/wallet/get-price?`,
//   toaddress: `${wallet}/wallet/get-all-coin-with-wallet-address`,

//   myAddress: `${wallet}/wallet/get-address-new`,
//   withdraw: `${wallet}/wallet/withdraw`,
//   approveWithdraw: `${wallet}/wallet/approve-withdraw`,

//   cancelWithdraw: `${wallet}/wallet/cancel-withdraw`,
//   deposits: `${wallet}/wallet/get-deposits`,
//   buyToken: `${wallet}/basic-exchange/buy-token`,
//   buyTokenBtc: `${wallet}/wallet/buy-token`,
//   minimumfee: `${wallet}/wallet/coin/get-coin-list`,

//   // admin /wallet/coin/get-coin-list
//   transactionHistoy: `${wallet}/get-all-transaction-history`,

//   // notification
//   getNotification: `${notification}/get-notification-data`,
//   readNotification: `${notification}/read-notification`,
//   clearNotification: `${notification}/clear-notification`,

//   // account
// };

// export default ApiConfig;








// // const url = "http://172.16.1.145:7010";
// // const url = "https://backend.togatoken.com";
// const url = "https://java-tokenico.mobiloitte.com";

// const user = `${url}/account`;
// const auth = `${url}/auth`;
// const account = `${url}/account`;
// const wallet = `${url}/wallet`;
// const static1 = `${url}/static`;
// const admin = `${url}/wallet/admin`;
// const notification = `${url}/notification`;

// const ApiConfig = {
//   userLogin: `${auth}`,
//   getCoinList: `${url}/wallet/coin/get-coin-list`,
//   userSignUp: `${user}/signup`,
//   verifyOTP: `${user}/verify-user`,
//   resendOTP: `${user}/resend-verify-email`,
//   forgotPassword: `${user}/forget-password/mobiloitApp`, ///verify-sms-code-mobile-app
//   verifyOTPSMS: `${user}/verify-sms-code-mobile-app`, //verify-sms-code-mobile-app
//   resetPassword: `${user}/reset-password-mobile-app`,
//   getKYCDetails: `${account}/get-kyc-details`,
//   adminstatusforkyc: `${account}/get-check-kyc-status`,
//   // editUserProfile: `${user}/profile-update`,
//   getCountryList: `${user}/get-country-list`,
//   getTransactionHistory: `${url}/wallet/wallet/get-all-transaction-history`,

//   addSubscribe: `${user}/add-subscribe`,

//   // account

//   myAccount: `${account}/my-account`,
//   userBankAccount: `${account}/add-user-bank-account`,
//   userAccountDetails: `${account}/get-user-account-detail`,
//   userAccountList: `${account}/get-user-account-list`,
//   userAccountDelete: `${account}/delete-user-bank-account`,
//   saveKycDetails: `${account}/save-kyc-details`,
//   uploadFile: `${account}/upload-file`,
//   getKycList: `${account}/get-all-kyc-details`,
//   editUserProfile: `${account}/profile-update`,
//   CountryList: `${account}/get-country-list`,
//   searhList: `${account}/search-and-filter-bank-account-list`,
//   newsletter: `${account}/get-all-news-letter-for-website`,
//   whitePaperList: `${account}/get-whitepaper-full-list`,

//   // wallet

//   myWallet: `${wallet}/get-all-user-balance-and-coinlist`,
//   // static
//   contactUS: `${static1}/submit-support-ticket`,
//   postnewsLetter: `${static1}/post-news-letter-to-user`,
//   // term: `${static1}/get-all-static-content-data`,
//   faq: `${static1}/search-filter-faq-list`,
//   term: `${static1}/get-static-page-data-by-page-key`,
//   Aboutus: `${static1}/get-all-static-content-data`,
//   policy: `${static1}/get-all-static-content-details-for-web`,
//   GetAllCommunityForWebsite: `${account}/get-all-community-for-website`,
//   // policy: `${static1}/get-static-page-data-by-page-key?pageKey=Privacy_Policy`,
//   myWallet: `${wallet}/wallet/get-all-user-balance-and-coinlist`,
//   getCurrentprice: `${wallet}/wallet/get-price?`,
//   toaddress: `${wallet}/wallet/get-all-coin-with-wallet-address`,

//   myAddress: `${wallet}/wallet/get-address-new`,
//   withdraw: `${wallet}/wallet/withdraw`,
//   approveWithdraw: `${wallet}/wallet/approve-withdraw`,

//   cancelWithdraw: `${wallet}/wallet/cancel-withdraw`,
//   deposits: `${wallet}/wallet/get-deposits`,
//   buyToken: `${wallet}/basic-exchange/buy-token`,
//   buyTokenBtc: `${wallet}/wallet/buy-token`,
//   minimumfee: `${wallet}/wallet/coin/get-coin-list`,

//   // admin /wallet/coin/get-coin-list
//   transactionHistoy: `${wallet}/get-all-transaction-history`,

//   // notification
//   getNotification: `${notification}/get-notification-data`,
//   readNotification: `${notification}/read-notification`,
//   clearNotification: `${notification}/clear-notification`,

//   // account
// };

// export default ApiConfig;
